
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/all/[categoryName]",
      function () {
        return require("private-next-pages/all/[categoryName]/index.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/all/[categoryName]"])
      });
    }
  